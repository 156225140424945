import React from 'react'
import CartPage from '~/components/Cart/Page'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { useTranslate } from '@arfabrands/gatsby-theme-assembly'

const constants = {
  backgroundColor: '#265C5E',
  navTextColor: '#91D3CA',
  logoColor: '#FFFFFF',
}

const Cart = () => {
  const translate = useTranslate()

  return (
    <Layout
      backgroundColor={constants.backgroundColor}
      navTextColor={constants.navTextColor}
    >
      <Metadata title={translate('cart.page_title')} />
      <CartPage />
    </Layout>
  )
}

export default Cart

/** @jsx jsx */
import {
  logSentryError,
  selectors,
  useAnalytics,
  useCart,
  useTranslate,
  utils
} from '@arfabrands/gatsby-theme-assembly'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Container, Flex, Heading, jsx, Spinner } from 'theme-ui'
import LineItems from '~/components/Cart/LineItems'
import CartSummary from '~/components/Cart/Summary'

const CartContainer = ({ children }) => {
  return (
    <Container sx={{
      padding: '1.5rem'
    }}>
      <Flex
        sx={{
          flexDirection: 'column',
          marginBottom: ['1.5rem', null, '4.5rem'],
          marginTop: ['0', null, '42px']
        }}
      >
        {children}
      </Flex>
    </Container>
  )
}

const CartLoading = () => {
  return (
    <CartContainer>
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          backgroundColor: 'white',
          padding: ['10rem 1.25rem', '12rem 1.25rem'],
          marginRight: [null, '1rem'],
          marginBottom: ['1rem', null]
        }}
      >
        <Spinner size="50" />
      </Box>
    </CartContainer>
  )
}

const CartEmpty = () => {
  const translate = useTranslate()

  return (
    <CartContainer>
      <Box
        sx={{
          borderRadius: '0.25rem',
          textAlign: 'center',
          width: '100%',
          backgroundColor: 'white',
          padding: ['2rem 1.25rem', '3rem 1.25rem'],
          marginRight: [null, '1rem'],
          marginBottom: ['1rem', null]
        }}
      >
        <Heading as="h4" mb="2rem" mt="0.5rem"
          sx={{
            color: 'rgb(0, 15, 159)',
            fontSize: '34px',
          }}
        >
          {translate('cart.empty')}
        </Heading>
        <Button
          as={Link}
          to="/shop"
          sx={{
            color: 'rgb(81, 39, 115)',
            backgroundColor: 'rgb(197, 179, 215)',
            '&:hover': {
              backgroundColor: 'rgb(197, 179, 215)',
              filter: 'brightness(0.9)',
            },
            borderRadius: '0.25rem',
            fontFamily: 'DIN Alternate Bold,Helvetica,Arial,Sans-Serif',
            marginTop: '1rem',
            padding: '12px 16px',
            transition: 'filter 0.15s ease-in-out 0s',
          }}
        >
          {translate('cart.shop_button')}
        </Button>
      </Box>
    </CartContainer>
  )
}

const CartNotEmpty = ({
  lineItems,
  displayTotal,
  displayItemTotal,
  displayTaxTotal,
  displayShipTotal,
  promotions,
  eligibleForFreeShipping,
  amountNeededForFreeShipping
}) => {
  return (
    <CartContainer sx={{

    }}>
      <Flex
        sx={{
          flexDirection: ['column', null, 'row'],
          justifyContent: 'space-around'
        }}
      >
        <Flex
          sx={{
            flexGrow: 1,
            height: '100%',
            maxWidth: [null, null, '60%'],
            backgroundColor: 'white',
            borderRadius: '0.25rem',
            padding: ['24px 16px', null, '42px 36px'],
            marginRight: [null, null, '1rem'],
            marginBottom: '1rem'
          }}
        >
          <LineItems
            items={lineItems}
            amountNeededForFreeShipping={amountNeededForFreeShipping}
            eligibleForFreeShipping={eligibleForFreeShipping}
          />
        </Flex>
        <Flex
          sx={{
            flexGrow: 1,
            height: '100%',
            backgroundColor: 'white',
            padding: ['16px', null, '36px']
          }}
        >
          <CartSummary
            displayItemTotal={displayItemTotal}
            displayTaxTotal={displayTaxTotal}
            displayShipTotal={displayShipTotal}
            displayTotal={displayTotal}
            promotions={promotions}
            eligibleForFreeShipping={eligibleForFreeShipping}
          />
        </Flex>
      </Flex>
    </CartContainer>
  )
}

const CartPage = () => {
  const { cart, loadCart } = useCart()
  const { trackViewCart } = useAnalytics()
  const { getAmountNeededForFreeShipping } = selectors
  const { getAllCartPromotionsForDisplay } = utils

  useEffect(() => {
    const loadAndTrackCart = async () => {
      try {
        await loadCart()
        trackViewCart()
      } catch (error) {
        logSentryError(error, { source: 'Cart' })
      }
    }

    loadAndTrackCart()
  }, [loadCart, trackViewCart])

  const amountNeededForFreeShipping = useSelector(
    getAmountNeededForFreeShipping
  )
  const eligibleForFreeShipping = amountNeededForFreeShipping === 0
  const { isFetching, data: order } = cart

  if (isFetching || !order.lineItems)
    return <CartLoading />

  const {
    displayTotal,
    displayItemTotal,
    displayTaxTotal,
    displayShipTotal,
    lineItems
  } = order

  const promotions = getAllCartPromotionsForDisplay(order)
  
  if (!isFetching && order.lineItems && order.lineItems.length !== 0)
    return (
      <CartNotEmpty
        lineItems={lineItems}
        displayTotal={displayTotal}
        displayItemTotal={displayItemTotal}
        displayTaxTotal={displayTaxTotal}
        displayShipTotal={displayShipTotal}
        promotions={promotions}
        amountNeededForFreeShipping={amountNeededForFreeShipping}
        eligibleForFreeShipping={eligibleForFreeShipping}
      />
          )
          
  if (!isFetching && order.lineItems.length === 0) return <CartEmpty />     
}

CartNotEmpty.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.shape({})),
  displayTotal: PropTypes.string,
  displayItemTotal: PropTypes.string,
  displayTaxTotal: PropTypes.string,
  displayShipTotal: PropTypes.string,
  promotions: PropTypes.arrayOf(PropTypes.shape({}))
}

export default CartPage
